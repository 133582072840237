.contenedor_concepto{
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width:550px) {
        height: 80vh;
        padding-top: 40px;
        min-height: 700px;
    }
    .titulo_concepto{
        width: 100%;
        border-top:3px solid #C2BA98 ;
        border-bottom: 3px solid #C2BA98;
        padding: 20px 40px;
        display: flex;
        justify-content: center;
        @media (min-width:1600px) {
            max-width: 500px;
        }
        @media (max-width:1024px) {
            padding: 30px 40px;
        }
        @media (max-width:550px) {
            padding: 30px 20px;
        }
        h1{
            color: #373A36;
            font-size: 40px;
            @media (max-width:550px) {
                font-size: 28px;
            }
        }
    }
    .texto_concepto{
        width: 37%;
        @media (min-width:1600px) {
            width: 30%;
            max-width: 525px;
        }
        @media (max-width:1024px) {
            width: 60%;
        }
        @media (max-width:550px) {
            width: 80%;
        }
        p{
            text-align: center;
            color: #000000;
            font-family: "montserrat regular";
            @media (max-width:550px) {
                font-size: 15px;
                text-align: left;
            }
        }
    }
    .button_dispo{
        a{
            cursor: pointer;
            background-color: #C2BA98;
            color: #373A36;
            padding: 10px 20px;
            text-decoration: none;
        }
    }
}