.contenedor-prototipo{
    height: 272px;
    width: 100%;
    background: #373A36;
    .contenedor-prototipo-principal{
    height: 270px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px 40px;
    @media (max-width:550px){
        padding: 25px 0px 25px;
    }
    h2{
        color: #C2BA98;
        font-family: "athelas regular";
        font-weight: lighter;
        font-size: 35px;
    }
    p{
        color: #C2BA98;
        font-family: "montserrat regular";
        font-size: 14px;
        @media (max-width:550px){
            font-size: 13px;
            text-align: center;
        }
    }
    .contenedor-textos{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width:550px){
            padding: 0px 48px;
        }
    }
    }
}