
.prototype-container{
    background-color: #C2BA98;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 80px;
    gap: 40px;
    @media (min-width:1600px) {
        padding: 55px 140px;
    }
    @media (max-width: 1366px) {
        padding: 55px 40px;
    }
    @media (max-width: 1280px) {
        gap: 50px;
    }
    @media (max-width: 550px) {
        padding: 30px 20px;
        gap: 40px;
    }
    .iso{
        width: 80px;
        @media (max-width: 550px) {
            width: 65px;
        }
    }
    h2{
        font-size: 42px;
        color: #373A36;
        border-top: 4px solid #373A36;
        border-bottom: 4px solid #373A36;
        padding: 10px;
        @media (max-width: 550px) {
            font-size: 35px;
            border-top: 3px solid #373A36;
            border-bottom: 3px solid #373A36;
        }
    }
    .text-ground{
        width: 340px;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        @media (max-width: 550px) {
            flex-direction: column;
            align-items: center;
        }
        p{
            color: #373A36;
            @media (max-width: 550px) {
                font-size: 17px;
            }
        }
    }
    &.brown{
        background-color: #B8B090;
    }
    .prototype-container-detail{
        display: flex;
        width: 100%;
        margin-top: 30px;
        gap: 40px;
        @media (min-width:1600px) {
            gap: 50px
        }
        @media (max-width: 1280px) {
           flex-wrap: wrap;
           justify-content: space-between;
           margin-top: 0;
           gap: 50px;
        }
        @media (max-width: 550px) {
            gap: 40px;
        }
        .container-carousel{
            width: 27%;
            height: 470px;
            @media (min-width: 1600px) {
                height: 500px;
            }
            @media (max-width: 1280px) {
                width: 100%;
                height: 600px;
            }
            @media (max-width: 550px) {
                height: 400px;
            }
        }
        .container-detail{
            width: 36%;
            height: 500px;
            @media (max-width: 1280px) {
                width: 47%;
            }
            @media (max-width: 1024px) {
                width: 100%;
                height: auto;
            }
        }
    }
    button{
        background-color: #373A36;
        border: none;
        color: #C2BA98;
        padding: 10px;
        font-size: 19px;
        font-family: "athelas regular";
        cursor: pointer;
        @media (max-width: 550px) {
            font-size: 15px;
        }
    }
}