.contenedor_footer{
    width: 100%;
    height: 380px;
    background-color: #373A36;
    padding: 30px 0;
    display: grid;
    grid-template-columns: 1fr 2.5fr 1fr;
    grid-template-rows: 150px auto;
    @media (min-width: 1600px) {
        grid-template-columns: 1fr 1.5fr 1fr;
    }
    @media (max-width:550px) {
        grid-template-rows: 100px auto;
        grid-template-columns: 1fr 1fr 1fr;
    }
    &_social{
        grid-column: 1/2;
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 50%;
        @media (min-width: 1600px) {
            width: 30%;
        }
        @media (max-width:1024px) {
            width: 50%;
        }
        img{
            width: 30px;
            @media (max-width:550px) {
                width: 15px;
            }
        }
    }
    &_logo{
        grid-column: 2/3;
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 150px;
            @media (max-width:550px) {
                width: 100px;
            }
        }
    }
    &_tel{
        grid-column: 3/4;
        grid-row: 1/2;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            text-decoration: none;
            color: #C2BA98;
            font-size: 25px;
            @media (min-width:1600px) {
                font-size: 28px;
            }
            @media (max-width:550px) {
                font-size: 13px;
            }
        }
    }
    &_info{
        grid-column: 2/3;
        grid-row: 2/3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        @media (max-width:1024px) {
            grid-column: 1/4;
            padding: 0 20px;
        }
        p{
            color: white;
            font-family: "montserrat regular";
            font-size: 11px;
            text-align: center;
            @media (max-width:550px) {
                font-size: 10px;
            }
            a{
                color: white;
                cursor: pointer;
                &:hover{
                    color: #C2BA98;
                }
            }
        }
        div{
            display: flex;
            align-items: center;
            p{
                color: white;
            }
            img{
                width: 90px;
                margin: 0 10px;
                @media (max-width:550px) {
                    width: 70px;
                }
            }
        }
    }
}