
.contenedor_hero_casas{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 700px;
    @media (max-width:550px) {
        min-height: 700px;
    }
    &_fondo{
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    &__slider_card_logo{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: end;
        z-index: 9;
        @media (min-width:1600px) {
            height: 95%;
            width: 50%;
        }
        @media (max-width:1024px) {
            height: 95%;
            width: 70%; 
        }
        @media (max-width:550px) {
            height: 96%;
            width: 80%;
        }
        .contenedor_hero_casas_principal{
            height: 60%;
            justify-content: space-around;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50PX;
            @media (min-width:1600px) {
                height: 65%;
            }
            @media (max-width:1024px) {
                height: 70%;
                width: 70%; 
            }
            @media (max-width:550px) {
                height: 60%;
                width: 80%;
            }
            @media (max-width:375px) {
                height: 65%;
            }
            h1{
                color: #C2BA98;
                font-size: 50px;
                font-weight: normal;
                text-align: center;
                @media (max-width: 550px) {
                    font-size: 30px;
                }
                @media (max-width:360px) {
                    font-size: 28px;
                }
            }
            .button_dispo_acacia{
                z-index: 9;
                a{
                    cursor: pointer;
                    padding: 10px 20px;
                    background-color: #C2BA98;
                    color: #373A36;
                    text-decoration: none;
                }
                a:hover{
                    background-color: #373a36;
                    color: #ffffff;
                }
            }
        }
    }
    .bottom-info-casas{
        display: flex;
        z-index: 9;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        background-color: #373a36;
        align-items: center;
        position: relative;
        .texto_acacia{
            display: flex;
            justify-content: center;
            width: 100%;
            p{
                color: #c2ba98;
                font-family: "athelas regular";
                font-size: 17px;
                letter-spacing: 8px;
                @media (max-width:550px) {
                    letter-spacing: 6px;
                    font-size: 14px;
                }
            }
        }
        .whatsapp{
            margin-left: 30px;
            position: fixed;
            width: 30%;
            bottom: 0;
            z-index: 999;
            div{
                align-items: center;
                display: flex;
                height: 50px;
                justify-content: center;
                width: 50px;
                background-color: #373a36;
                cursor: pointer;
                &.mostrar{
                    @media (max-width:550px) {
                        display: flex;
                    }
                }
                &.ocultar{
                    @media (max-width:550px) {
                        display: none;
                    }
                }
                a{
                    
                    img{
                        width: 30px;
                        @media (min-width:1600px) {
                            height: 40px;
                        }
                        @media (max-width:550px) {
                            width: 25px;
                        }
                    }
                }
            }
        }
        .icon-scroll,
        .icon-scroll:before{
            position: absolute;
            left: 50%;
        }
        .icon-scroll{
            width: 28px;
            height: 50px;
            margin-left: -11px;
            box-shadow: inset 0 0 0 1px #C2BA98;
            border-radius: 25px;
            top: -65px;
           @media (max-width:550px) {
            top: -60px;
           }
        }
        .icon-scroll:before{
            content: '';
            width: 4px;
            height: 6px;
            background: #C2BA98;
            margin-left: -2px;
            top: 0px;
            border-radius: 5px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
        }
    }
}