.contenedor_home_artemisa_desc{
    background-color: #373A36;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    @media (min-width:1600px) {
        height: 100vh;
    }
    @media (max-width:1024px) {
        height: 100vh;
    }
    @media (max-width:550px) {
        min-height: 700px;
    }
    .contenedor_home_artemisa_fondo{
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    .contenedor_home_artemisa__slider_card_logo{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 9;
        @media (min-width:1600px) {
            height: 95%;
            width: 50%;
        }
        @media (max-width:1024px) {
            height: 95%;
            width: 70%; 
            align-items: center;
        }
        @media (max-width:550px) {
            height: 96%;
        }
        img{
            width: 350px;
            margin-top: 340px;
            @media (min-width:1600px) {
                width: 400px;
                margin-top: 400px;
            }
            @media (max-width:1024px) {
                width: 450px;
                margin-top: 300px;
            }
            @media (max-width:550px) {
                width: 250px;
                margin-top: 205px;
            }
        }
        .contenedor-home-principal-artemisa{
            height: 80%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (min-width:1600px) {
                height: 73%
            }
            @media (max-width:1024px) {
                height: 50%;
                width: 70%; 
            }
            @media (max-width:550px) {
                height: 400px;
                width: 80%;
            }
        // @media (max-width:375px) {
        //     height: 80%;
        // }
        // @media (max-width:360px) {
        //     height: 85%;
        //     justify-content: space-evenly;
        // }

        }
        .button_dispo_artemisa{
            z-index: 9;
            a{
                cursor: pointer;
                padding: 10px 20px;
                background-color: #C2BA98;
                color: #373A36;
                text-decoration: none;
            }
            a:hover{
                background-color: #373a36;
                color: #ffffff;
            }
        }
    }
    .bottom-info-home-artemisa{
        display: flex;
        z-index: 9;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        background-color: #373a36;
        align-items: center;
        position: relative;
        // @media (max-width:360px) {
        //     display: none;
        // }
        .icon-scroll,
        .icon-scroll:before{
            position: absolute;
            left: 50%;
        }
        .icon-scroll{
            width: 28px;
            height: 50px;
            margin-left: -11px;
            // margin-top: 642px;
            box-shadow: inset 0 0 0 1px #C2BA98;
            border-radius: 25px;
            top: -50px;
            @media (min-width:1600px) {
                // margin-top: 800px;
            }
            @media (max-width:1024px) {
                // margin-top: 730px;
            }
            @media (max-width:550px) {
                // margin-top: 525px;
                top: -50px;
            }
            @media (max-width:375px) {
                // margin-top: 415px;
            }
            @media (max-width:360px) {
                // margin-top: 615px;
            }
        }
        .icon-scroll:before{
            content: '';
            width: 4px;
            height: 6px;
            background: #C2BA98;
            margin-left: -2px;
            top: 0px;
            border-radius: 5px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
        }
        .whatsapp{
            margin-left: 30px;
            position: fixed;
            width: 30%;
            div{
                align-items: center;
                display: flex;
                height: 50px;
                justify-content: center;
                width: 50px;
                background-color: #373a36;
                cursor: pointer;
                a{
                    
                    img{
                        width: 30px;
                        @media (min-width:1600px) {
                            height: 40px;
                        }
                        @media (max-width:550px) {
                            width: 25px;
                            display: none;
                        }
                    }
                }
            }
        }
        .texto_artemisa{
            z-index: 9;
            display: flex;
            justify-content: center;
            width: 100%;
            p{
                color: #c2ba98;
                font-family: athelas regular;
                font-size: 15px;
                letter-spacing: 8px;
                @media (max-width:550px) {
                    font-size: 10px;
                    letter-spacing: 6px;
                }
            }
            
        }
    }
}