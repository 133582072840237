.container_slider_thumbnail{
    width: 100%;
    height: 100%;
}
.swiper {
    width: 100%;
    height: 100%;
    cursor: pointer;
    .swiper-wrapper{
        background-color: #C2BA98;
    }
}
.swiper-pagination-horizontal{
    top: 350px;
    @media(max-width:1024px){
        top: 420px;
    }
    @media(max-width:550px){
        top: 290px;
    }
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prototype-carousel-slides {
    height: 80%;
    width: 100%;
}
  
.prototype-carousel-thumb {
    height: 20%;
    box-sizing: border-box;
    padding: 15px 0 0;
    background-color: #C2BA98;
}
  
.prototype-carousel-thumb .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}
  
.prototype-carousel-thumb .swiper-slide-thumb-active {
    opacity: 1;
}