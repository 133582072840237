.modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.726);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .close{
        position: absolute;
        right: 15%;
        z-index: 9;
        font-family: "montserrat regular";
        font-size: 30px;
        color: white;
        top: 5%;
        cursor: pointer;
        cursor: pointer;
        @media (max-width:1024px) {
            top: 25%;
            right: 20px;
        }
        @media (max-width: 550px) {
            font-size: 20px;
            top: 32%;
            right: 30px;
        }
    }
    .modal-s{
        width: 60%;
        height: 90%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:1024px) {
            height: 40%;
            width: 90%;
        }
        .img{
            width: 100%;
            object-fit: contain;
        }
    }
}