.contenedor_aviso{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #373A36;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .closed{
        font-size: 30px;
        color: white;
        font-family: "montserrat regular";
        position: absolute;
        top: 5%;
        right: 10%;
        cursor: pointer;
    }
    &_card{
        width: 80%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        @media (min-width:1600px) {
            width: 70%;
            height: 80%;
        }
        @media (max-width:550px) {
            width: 100%;
            height: 90%;
        }
        h1{
            color: #C2BA98;
        }
        &_texto{
            width: 100%;
            max-height: 400px;
            overflow-y: auto;
            @media (max-width:550px) {
                width: 90%;
            }
            p{
                font-family: "montserrat regular";
                font-size: 15px;
                color: white;
                margin-bottom: 10px;
            }
        }
    }
}