.contenedor_home_acacia_desc{
    background-color: #373A36;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    @media (min-width:1600px) {
        height: 100vh;
    }
    @media (max-width:1024px) {
        height: 100vh;
    }
    @media (max-width:550px) {
        min-height: 700px;
    }
    .contenedor_home_acacia_fondo{
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    .contenedor_home_acacia__slider_card_logo{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 9;
        img{
            width: 285px;
            margin-top: 340px;
            @media (min-width:1600px) {
                width: 350px;
                margin-top: 400px;
            }
            @media (max-width:1024px) {
                width: 400px;
                margin-top: 420px;
            }
            @media (max-width:550px) {
                height: 70%;
                width: 270px;
                margin-top: 290px;
            }
            @media (max-width:375px) {
                width: 220px;
                margin-top: 200px;
            }
        }
        @media (min-width:1600px) {
            height: 95%;
            width: 50%;
        }
        @media (max-width:1024px) {
            height: 95%;
            width: 70%; 
        }
        @media (max-width:550px) {
            height: 96%;
            width: 80%;
        }
        @media (max-width:375px) {
            // height: 75%;
        }
        .contenedor-home-principal{
        height: 80%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width:1600px) {
            height: 73%;
        }
        @media (max-width:1024px) {
            height: 70%;
            width: 70%; 
        }
        @media (max-width:550px) {
            height: 75%;
            width: 80%;
        }
        @media (max-width:375px) {
            height: 75%;
        }
        }
    }
    .button_dispo_acacia{
        z-index: 9;
        a{
            cursor: pointer;
            padding: 10px 20px;
            background-color: #C2BA98;
            color: #373A36;
            text-decoration: none;
        }
        a:hover{
            background-color: #373a36;
            color: #ffffff;
        }
    }
    .texto_acacia{
        z-index: 9;
        display: flex;
        justify-content: center;
        width: 100%;
        // @media (max-width:375px) {
        //     display: none;
        //    }
        p{
            color: #c2ba98;
            font-family: athelas regular;
            font-size: 10px;
            letter-spacing: 8px;
            @media (max-width:550px) {
                letter-spacing: 6px;
            }
        }
    }
    .bottom-info-home{
        display: flex;
        z-index: 9;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        background-color: #373a36;
        align-items: center;
        position: relative;
        // @media (max-width:375px) {
        //     display: none;
        // }
        .whatsapp{
            margin-left: 30px;
            position: fixed;
            width: 30%;
            div{
                align-items: center;
                display: flex;
                height: 50px;
                justify-content: center;
                width: 50px;
                background-color: #373a36;
                cursor: pointer;
                // @media (max-width:550px) {
                //             display: none;
                //         }
                a{
                    
                    img{
                        width: 30px;
                        @media (min-width:1600px) {
                            height: 40px;
                        }
                        @media (max-width:550px) {
                            width: 25px;
                            display: none;
                        }
                    }
                }
            }
        }
        .icon-scroll,
        .icon-scroll:before{
            position: absolute;
            left: 50%;
        }
        .icon-scroll{
            width: 28px;
            height: 50px;
            margin-left: -11px;
            // margin-top: 642px;
            box-shadow: inset 0 0 0 1px #C2BA98;
            border-radius: 25px;
            top: -50px;
            @media (min-width:1600px) {
                // margin-top: 800px;
           }
           @media (max-width:1024px) {
            // margin-top: 875px;
           }
           @media (max-width:550px) {
            // margin-top: 745px;
            top: -50px;
           }
           @media (max-width:375px) {
            // margin-top: 500px;
           }
           @media (max-width:360px) {
            // margin-top: 550px;
           }
        }
        .icon-scroll:before{
            content: '';
            width: 4px;
            height: 6px;
            background: #C2BA98;
            margin-left: -2px;
            top: 0px;
            border-radius: 5px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
        }
    }
    }
