.container-prototypes{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
    @media (max-width: 550px) {
        gap: 10px;
    }
    .container-secundary-prototypes{
        flex: 1;
        .contenedor-title-main{
            .contenedor-title{
                .title{
                    font-family: 'athelas regular';
                    font-size: 22px;
                    color: #373A36;
                    font-weight: bold;
                }
                .metros{
                    font-family: 'athelas regular';
                    color: #373A36;
                    font-size: 20px;
                    .super{
                        font-size: 19px;
                    }
                }
            }
        }
        .bottom_list{
            width: 100%;
            height: 80%;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            ul {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }
    .container-image{
        flex: 0 0 180px;
        @media (max-width: 550px) {
            flex: 0 0 135px;
        }
        img{
            width: 100%;
        }
    }
}
.container-list{
    margin-bottom: 5px;
}
.container-list-s{
    display: flex;
    img{
        margin-right: 5px;

    }
}
    .characteristics{
        font-family: 'montserrat medium';
        font-size: 17px;
        list-style: none;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }
        .img{
            flex: none;
            @media (max-width: 550px) {
                width: 15px;
            }
        }
        .additional{
            color: #373A36;
            font-family: 'montserrat italic';
        }

