.contenedor_amenidades_desc{
    background-color: #373A36;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (min-width:1600px) {
        height: 90vh;
    }
    @media (max-width:1024px) {
        height: 90vh;
    }
    .swiper{
        width: 100%;
        height: 100%;
    }
    &_fondo{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
    &__slider{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 9;
        width: 100%;
        &_card{
            height: 70%;
            width: 53%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            @media (min-width:1600px) {
                height: 60%;
                width: 50%;
            }
            @media (max-width:1024px) {
                height: 50%;
                width: 70%; 
            }
            @media (max-width:550px) {
                height: 70%;
                width: 80%;
            }
            &_logo{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 300px;
                    @media (max-width:550px) {
                        width: 220px;
                    }
                }
            }
            &_metros{
                width: 55%;
                height: 10%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 3px solid #C2BA98;
                @media (min-width:1600px) {
                    width: 50%;
                }
                @media (max-width:1024px) {
                    width: 80%;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
                p{
                    font-size: 17px;
                    font-family: "montserrat regular";
                    color: white;
                    @media (min-width:1600px) {
                        font-size: 19px;
                    }
                    @media (max-width:550px) {
                        font-size: 15px;
                    }
                }
            }
            &_lista{
                width: 70%;
                height: 30%;
                display: grid;
                grid-template-columns: 168px 1fr;
                grid-template-rows: auto;
                @media (min-width:1600px) {
                    width: 57%;
                    height: 25%;
                    grid-template-columns: 250px 1fr;
                }
                @media (max-width:1024px) {
                    width: 90%;
                    grid-template-columns: 210px 1fr;
                }
                @media (max-width:550px) {
                   width: 85%;
                   height: 40%;
                   grid-template-columns: 1fr;
                }
                .amenidad{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    // width: 50%;
                    @media (max-width:550px) {
                       width: 100%;
                    }
                    div{
                        width: 90%;
                        display: flex;
                        align-items: center;
                        img{
                            width: 25px;
                            margin-right: 10px;
                        }
                        p{
                            font-size: 17px;
                            font-family: "montserrat regular";
                            color: white;
                            @media (min-width:1600px) {
                                font-size: 19px;
                            }
                            @media (max-width:550px) {
                               font-size: 15px;
                            }
                        }
                    }
                }
            }
            .button_dispo{
                a{
                    cursor: pointer;
                    padding: 10px 20px;
                    background-color: #C2BA98;
                    color: #373A36;
                    text-decoration: none;
                }
                a:hover{
                    background-color: #373a36;
                    color: #ffffff;
                }
            }
        }
    }
}