.contenedor_mapa{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #373A36;
    align-items: center;
    @media (max-width:1024px) {
       flex-direction: column-reverse;
    }
    @media (max-width:550px) {
        height: 160vh;
    }
    .mapa{
        width: 50%;
        height: 100%;
        @media (max-width:1024px) {
            width: 100%;
            height: 40%;
        }
        @media (max-width:550px) {
            height: 30%;
        }
    }
    .ubicaciones{
        width: 50%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width:1600px) {
            height: 60%;
        }
        @media (max-width:1024px) {
            width: 100%;
            height: 60%;
            justify-content: center;
        }
        @media (max-width:550px) {
            height: 70%;
            justify-content: flex-start;
            padding-top: 50px;
        }
        .top{
            width: 80%;
            height: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            @media (max-width:1024px) {
                height: 40%;
            }

            &_subtitulo{
                width: 100%;
                border-top: 3px solid #C2BA98;
                border-bottom: 3px solid #C2BA98;
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (min-width:1600px) {
                    // width: 45%;
                }
                @media (max-width:550px) {
                    // width: 80%;
                    padding: 20px;
                }
                h2{
                    color: #C2BA98;
                    font-size: 40px;
                    text-align: center;
                    @media (max-width:550px) {
                        font-size: 28px;
                    }
                }
            }
            &_texto{
                width: 65%;
                @media (min-width:1600px) {
                    width: 65%;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
                p{
                    color: #C2BA98;
                    text-align: center;
                    @media (min-width:1600px) {
                        font-size: 20px;
                    }
                    @media (max-width:550px) {
                        font-size: 15px;
                    }
                }
            }
        }
        .bottom {
            width: 80%;
            height: 50%;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            @media (max-width:1024px) {
                height: 30%;
                width: 65%;
            }
            @media (max-width:550px) {
                height: 40%;
                width: 95%;
                margin-top: 20px;
            }
            &_ubicaciones{
                width: 75%;
                @media (min-width:1600px) {
                    width: 65%;
                }
                @media (max-width:550px) {
                   width: 85%;
                }
                .row_list{
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    @media (min-width:1600px) {
                        margin-bottom: 10px;
                    }
                    @media (max-width:550px) {
                       margin-bottom: 5px;
                    }

                    img{
                        width: 25px;
                        margin-right: 10px;
                    }
                    p{
                        color: white;
                        font-family: "montserrat regular";
                        font-size: 14px;
                        @media (min-width:1600px) {
                            font-size: 17px;
                        }
                    }
                }
            }
            .mapas{
                width: 60%;
                display: flex;
                justify-content: space-between;
                @media (min-width:1600px) {
                    width: 50%;
                }
                @media (max-width:550px) {
                    width: 65%;
                }
                img{
                    width: 150px;
                    @media (max-width:550px) {
                        width: 110px;
                    }
                }
                a{
                    &:nth-child(2){
                        img{
                            width: 110px;
                            @media (max-width:550px) {
                                width: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}