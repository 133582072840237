.swiper-pagination-bullet{
    background-color: white !important;
    width: 15px !important;
    height: 15px !important;
    @media (max-width:550px) {
        width: 10px !important;
        height: 10px !important;
    }
}
.swiper-pagination-bullet-active{
    background-color: #373A36;
}

.swiper-pagination{
    bottom: 50px !important;
    @media (max-width:550px) {
       bottom: 20px !important;
    }
}