.contenedor_stiky{
    display: flex;
    width: 450px;
    height: 30px;
    position: fixed;
    right: -210px;
    top: 45%;
    z-index: 10;
    transform: rotate(270deg);
    justify-content: space-between;
    align-items: center;
    @media (max-width:550px) {
       right: -197px;
       width: 424px;
       height: 30px;
    }
    &.mostrar{
        @media (max-width:550px) {
            display: flex;
        }
    }
    &.ocultar{
        @media (max-width:550px) {
            display: none;
        }
    }
    &.not-galery{
        width: 300px;
        right: -135px;
        @media (min-width:1600px) {
            width: 320px;
            right: -145px;
        }
        .galeria{
            display: none;
        }
    }
    .brochure,.disponibilidad,.galeria{
        background-color: #C2BA98;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .brochure{
        width: 120px;
        p{
            cursor: pointer;
            color: #373A36;
        }
    }
    .disponibilidad{
        width: 150px;
        @media (min-width:1600px) {
            width: 180px;
        }
        a{
            text-decoration: none;
            color: #373A36;
            cursor: pointer;
        }
    }
    .galeria{
        width: 120px;
        a{
            text-decoration: none;
            cursor: pointer;
            color: #373A36;
        }
    }
}