

.contenedor_video{
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width:1024px) {
        flex-direction: column;
    }
    @media (max-width:550px) {
        height: 110vh;
        padding-top: 40px;
        min-height: 900px;
    }
    &__left{
        width: 25%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        @media (min-width:1600px) {
            height: 60%;
            width: 24%;
        }
        @media (max-width:1024px) {
            width: 100%;
            height: 60%;
        }
        @media (max-width:550px) {
            height: 70%;
        }
        &_subtitulo{
            border-top: 3px solid #C2BA98;
            border-bottom: 3px solid #C2BA98;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width:550px) {
                // width: 80%;
                padding: 20px;
            }
            h2{
                color: #373A36;
                font-size: 40px;
                @media (max-width:550px) {
                    font-size: 28px;
                }
            }
        }
        &_texto{
            @media (max-width:1024px) {
                width: 70%;
            }
            @media (max-width:550px) {
                width: 80%;
            }
            p{
                font-family: "montserrat regular";
                color: #373A36;
                text-align: center;
                @media (max-width:550px) {
                    font-size: 14px;
                }
            }
        }
        &_button{
            a{
                cursor: pointer;
                padding: 10px 20px;
                background-color: #C2BA98;
                color: #373A36;
                text-decoration: none;
            }
        }
    }
    &__right{
        width: 50%;
        height: 60%;
        position: relative;
        @media (min-width:1600px) {
            width: 50%;
            height: 60%;
        }
        @media (max-width:1024px) {
            width: 100%;
            height: 40%;
        }
        @media (max-width:550px) {
            height: 30%;
        }
        .play{
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 100px;
            z-index: 9;
            cursor: pointer;
            @media (max-width:550px) {
                width: 60px;
            }
        }
        &_video{
            position: relative;
            @media (max-width:1024px) {
                width: 100%;
                height: 100%;
                padding-bottom: 30px;
            }
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}