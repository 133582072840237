.menu-open{
    .header{
        background-color: #373A36;
        height: 100%;
        transition: all 0.5s ease;
        .menu{
            transform: translateY(0);
            @media (max-width:550px) {
               bottom: 80px;
            }
        }
        .social-media{
            display: flex;
        }
        &.back_menu .icon_logo{
            height: 75px !important;
        }
        span{
            background-color: transparent !important;
            &::before {
                top: 0 !important;
                transform: rotate(-45deg);
            }
            &::after {
                bottom: 0 !important;
                transform: rotate(45deg);
            }
        }
    }
}

.header{
    background-color: transparent;
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    transition: height 0.5s ease, background-color 1s linear;

    &.back_menu{
        background-color: #373A36;
        //transition: all 1s ease;
        .icon_logo{
            height: 75px !important;
            //transition: all 1s ease;
            img{
                width: 65px;
                @media (max-width:550px) {
                    width: 50px;
                }
               
            }
        }
    }
    .icon_menu{
        width: 20%;
        height: 75px;
        display: flex;
        color: white;
        align-items: center;
        padding-left: 60px;
        @media (max-width:1024px) {
            padding-left: 0;
        }
        @media (max-width:550px) {
            width: 27%;
        }
        p{
            font-family: "athelas regular";
            font-size: 25px;
            margin-left: 20px;
            @media (max-width:550px) {
                font-size: 14px;
                margin-left: 0;
            }
           &:nth-child(2){
            display: none;
           } 
        }
    }
    .icon_logo{
        width: 60%;
        height: 155px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width:550px) {
            width: 40%;
            height: 75px;
        }
        a{
            background-color: #373A36;
            padding: 13px;
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;
            @media (max-width:1024px) {
                width: 40%;
            }
            @media (max-width:550px) {
                width: 90%;
            }
            img{
                width: 150px;
                @media (min-width:1600px) {
                    width: auto;
                }
                @media (max-width:550px) {
                    width: 80%;
                }
            }
        }
    }
    .telefono{
        width: 20%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:550px) {
            width: 30%;
            height: 75px;
        }
        a{
            color: white;
            text-decoration: none;
            font-family: "athelas regular";
            font-size: 25px;
            @media (max-width:1024px) {
               font-size: 20px;
            }
            @media (max-width:550px) {
                font-size: 14px;
            }
        }
    }
    .btn-toggle {
        width: 50px;
        height: 50px;
        margin-right: 0px !important;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 3.8px;
            width: 60%;
            background-color: white;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color:white;
                position: absolute;
                left: 0;
                transition: width .5s;
            }
    
            &::before {
                top: -8px;
            }
            &::after {
                bottom: -7px;
            }
            &::before, &::after {
                transition: all .3s;
            }
        }
    }
    .menu{
        transform: translateY(-500px);
        position: absolute;
        bottom: 10px;
        width: 100%;
        height: 75%;
        transition: all 1s ease;
        ul{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: 100%;
            @media (min-width:1600px) {
                justify-content: space-between;
            }
            li{
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
               a{
                   cursor: pointer;
                color: #C2BA98;
                text-decoration: none;
                font-size: 25px;
                font-family: "athelas regular";
                @media (min-width:1600px) {
                    font-size: 30px;
                }
                @media (max-width:550px) {
                    font-size: 25px;
                }
               }
               img{
                   width: 150px;
                   margin-top: 30px;
                   @media (min-width:1600px) {
                    
                    }
               }
               ul{
                
                    justify-content: space-around;
                    height: 0;
                    overflow: hidden;
                    transition: all 0.5s ease-in;
               }
               &:hover{
                ul{
                    height: 120px;
                    padding-top: 20px;
                }
               }

            }
        }
    }
    .social-media{
        display: none;
        position: absolute;
        bottom: 10px;
        left: 80px;
        @media (max-width:1024px) {
            left: 5%;
        }
        @media (max-width:550px) {
            left: 35%;
         }
        img{
            width: 35px;
            margin-right: 20px;
            @media (max-width:1024px) {
                width: 25px;
            }
            @media (max-width:550px) {
                width: 20px;
             }
        }
    }
}
