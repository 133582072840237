.banner-principal{
    width: 100%;
    height: 235px;
    background-color: #c2ba98;
    position: relative;
    a{
        text-decoration: none;
    }
    .img-logo{
        position: absolute;
        display: flex;
        width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
        img{
            width: 315px;
        }
    }
    .contenedor-banner-letras{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 210px;
        h1{
            color: #373a36;
            font-family: 'athelas regular';
            font-size: 45px;
            font-weight: lighter;
            letter-spacing: 7.78px;
            @media (max-width:550px){
                font-size: 30px;
            }
        }
        p{
            font-size: 12px;
            font-family: 'athelas regular';
            color: #373A36;
        }
    }
    .banner-letras-separadas{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
        p{
            letter-spacing: 11.62px;
            font-size: 14px;
            color: #373A36;
            font-family: 'athelas regular';
            @media (max-width:550px){
                letter-spacing: 8.62px;
                a{
                    text-decoration: none;
                }
            }
        }
    }
}