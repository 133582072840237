.contenedor_recorrido_artemisa{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width:550px) {
        height: 75vh;
    }
    .disclamer{
        position: absolute;
        color: white;
        bottom: 100px;
        text-align: center;
        font-family: "montserrat regular";
        font-size: 12px;
        font-size: 85%;
        @media (max-width:550px) {
            font-size: 65%;
            bottom: 80px;
        }
    }
    .options{
        position: absolute;
        background-color: #C2BA98;
        width: 700px;
        height: 55px;
        display: flex;
        justify-content: space-evenly;
        bottom: 35px;
        @media (max-width:550px) {
            width: 80%;
            height: 40px;
        }
        button{
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: #373A36;
            font-size: 22px;
            font-family: "athelas regular";
            @media (max-width:550px) {
                font-size: 10px;
            }
            &:hover{
                color: #373A36;
            }
            &.active{
                color: #373A36;
                background-size: 100% 2px;
                text-decoration: none;
            }
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 85%;
            background-repeat: no-repeat;
            background-size: 0% 2px;
            transition: background-size 1s;
            &:hover{
                background-size: 100% 2px;
                text-decoration: none;
            }
        }
        &.artemisa{
            width: 400px;
            justify-content: space-around;
            @media (max-width:550px) {
                width: 250px;
            }
            button{
                @media (max-width:550px) {
                    font-size: 18px;
                }
            }
        }
    }
}