.contenedor_imagen_dispo{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #373A36;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    .closed{
        font-size: 30px;
        color: white;
        font-family: "montserrat regular";
        position: absolute;
        top: 5%;
        right: 8%;
        cursor: pointer;
    }
    .imagen{
        width: 80%;
        height: 90%;
        background: #373A36;
        background-repeat: no-repeat; 
        overflow: hidden;
        position: relative;
        @media (max-width:550px) {
            height: 70%;
        }
        // background-size: cover;
    }
    #map{
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
    }
}