.contenedor_disponibilidad{
    width: 100%;
    height: auto;
    background-color: #373A36;
    // @media (min-width:1600px) {
    //     height: 130vh;
    // }
    // @media (max-width:1024px) {
    //     height: 90vh;
    // }
    @media (max-width:550px) {
        // height: 130vh;
        padding-top: 50px;
    }
    .lugares-cercanos{
        // height: 700px;
        padding: 140px;
        @media (max-width:1024px) {
            padding: 80px 40px;
        }
        @media (max-width:550px) {
            padding: 80px 20px;
        }
        .dispo_top{
            width: 100%;
            padding-bottom: 100px;
            background-color: #373A36;
            display: flex;
            align-items: center;
            @media (max-width:1024px) {
                flex-direction: column;
            }
            @media (max-width:550px) {
                // height: 75%;
            }
            .subtitulo_dispo{
                width: 400px;
                border-top: 3px solid #C2BA98;
                border-bottom: 3px solid #C2BA98;
                padding: 20px;
                display: flex;
                justify-content: start;
                @media (max-width:1024px) {
                //    width: 35%;
                }
                @media (max-width:550px) {
                    // width: 80%;
                    width: auto;
                }
                h2{
                    color: #C2BA98;
                    font-size: 40px;
                    @media (max-width:550px) {
                        font-size: 28px;
                    }
                }
            }
            .texto_dispo{
                width: 70%;
                padding-left: 180px;
                @media (max-width:1024px) {
                    width: 70%;
                    padding-left: 0;
                    padding-top: 80px;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
                p{
                    color: white;
                    font-family: "montserrat regular";
                    @media (max-width:1024px) {
                       text-align: center;
                    }
                }
            }
        }
        .lugares_dispo{
            display: flex;
            width: 100%;
            height: 50%;
            justify-content: space-evenly;
            align-items: center;
            @media (max-width:1024px) {
                width: 100%;
                height: 30%;
                flex-direction: row;
            }
            @media (max-width:550px) {
                flex-wrap: wrap;
                width: 100%;
            }
            .animate__animated{
                @media (max-width:1024px) {
                    width: 80%;
                }
                @media (max-width:550px) {
                    width: 45%;
                }
            }
            .height-full{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .row_lugar{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 50%;
                img{
                    width: 100px;
                    padding-bottom: 50px;
                    @media (max-width:550px) {
                        width: 60px;
                        padding-bottom: 20px;
                    }
                }
                p{
                    font-family: "montserrat regular";
                    color: white;
                    @media (max-width:550px) {
                        font-size: 14px;
                        text-align: center;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }

    .bottom_mapa{
        width: 100%;
        height: 70%;
        position: relative;
        display: flex;
        justify-content: center;
        @media (max-width:1024px) {
            height: 40%;
        }
        @media (max-width:550px) {
            height: 25%;
            justify-content: flex-start;
        }
        .masterplan-text{
            position: absolute;
            top: 0;
            z-index: 9;
            padding: 50px 0;
            @media (max-width:550px) {
                left: 25%;
            }
            h2{
                color: white;
                font-size: 40px;
                font-weight: normal;
                padding-bottom: 10px;
                @media (max-width:1024px) {
                    font-size: 30px;
                }
                @media (max-width:550px) {
                    font-size: 28px;
                }
            }
            p{
                color: #C2BA98;
                text-align: center;
                font-size: 20px;
            }
        }
        .disclamer{
            position: absolute;
            color: white;
            bottom: 20px;
            right: 40px;
            text-align: center;
            font-family: "montserrat regular";
            font-size: 85%;
            @media (max-width:1024px) {
                padding: 5px;
                top: 10px;
                right: 20px;
            }
            @media (max-width:550px) {
                right: auto;
                bottom: 0px;
                top: auto;
                padding: 5px;
                font-size: 65%;
            }
        }
        img{
            width: 100%;
            height: auto;
        }
        &.fondoMasterplan{
            position: relative;
            width: 100%;
            background-color: #1F1E1C;
            // overflow-y: hidden;
            // overflow-x: scroll;
            overflow: hidden;
            @media (min-width:1600px){
                overflow: hidden;
            }
            @media (max-width: 1024px) {
                overflow-x: auto;
             }
        }
    }
}