.contenedor_galeria{
    width: 100%;
    background-color: #373A36;
    padding: 100px 50px 50px;
    @media (max-width:1024px) {
        padding: 80px 40px 20px;
    }
    .grid{
        display: flex;
        flex-wrap: wrap;    
        column-gap: 20px;
        row-gap: 20px;
        justify-content:center;
        .img-grid{
            width: 23%;
            height: 100%;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            @media (max-width:1024px) {
                width: 48%;;
            }
            @media (max-width:550px) {
                width: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.2s ease-in;
            }
            video{
                width: 100%;
                height: 100%;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
                .caption{
                    opacity: 1;
                }
            }
            .caption{
                opacity: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                display: flex;
                align-items: end;
                transition: all 0.5s ease-in;
                p{
                    padding: 20px;
                    color: white;
                    font-family: montserrat regular;
                    font-size: 17px;
                }
            }
        }    
    }
}