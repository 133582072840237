.contenedor_amenidades_artemisa{
    background-color: #C2BA98;
    width: 100%;
    height: 180vh;
    padding: 50px 0;
    @media (min-width:1600px) {
        height: 150vh;
    }
    @media (max-width:1024px) {
       height: 90vh;
    }
    @media (max-width:550px) {
        height: 140vh;
        padding: 0;
        padding-top: 50px;
    }
    .amenidades_top_artemisa{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 35%;
        @media (max-width:1024px) {
            flex-direction: column;
            height: 60%;
        }
        @media (max-width:550px) {
           height: 60%;
        }
        .subtitulo_ame_arte{
            width: 100%;
            border-top: 3px solid #373A36;
            border-bottom: 3px solid #373A36;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width:1024px) {
                // width: 35%;
            }
            @media (max-width:550px) {
                // width: 80%;
                padding: 20px;
            }
            h2{
                color: #373A36;
                font-size: 40px;
                @media (max-width:550px) {
                    font-size: 28px;
                }
            }
        }
        .list_ame_artemisa{
            display: flex;
            flex-wrap: wrap;
            width: 60%;
            height: 90%;
            justify-content: space-between;
            @media (min-width:1600px) {
                height: 80%;
            }
            @media (max-width:1024px) {
                width: 90%;
                height: 50%;
            }
            @media (max-width:550px) {
               height: 60%;
            }
            .colum_list_artemisa{
                width: 45%;
                display: flex;
                align-items: center;
               // margin-bottom: 10px;
                p{
                    font-family: "montserrat regular";
                    color: #373A36;
                    @media (max-width:550px) {
                        font-size: 13px;
                    }
                }
                img{
                    width: 40px;
                    margin-right: 20px;
                    @media (max-width:550px) {
                       width: 30px;
                       margin-right: 10px;
                    }
                }
            }
        }
    }
    .amenidades_bottom_artemisa{
        width: 100%;
        height: 60%;
        padding: 50px 80px 0;
        @media (min-width:1600px) {
            padding: 20px 80px 0;
            padding-top: 80px;
            height: 65%;
        }
        @media (max-width:1024px) {
           height: 40%;
           padding: 0;
        }
        @media (max-width:550px) {
            height: 40%;
            padding-top: 80px;
        }
        .swiper{
            width: 100%;
            height: 100%;
            --swiper-theme-color: #ffffff;

        }
        .contenedor__amenidades_carousel__slider_artemisa{
            width: 100%;
            height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    }
}